import React from "react";

function MountainSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M108.755 512a39.839 39.839 0 015.979-32.977l72.9-103.885-41.127-57.042c-1.995-2.768-5.053-4.152-8.111-4.152s-6.116 1.384-8.112 4.152L1.906 496.152C-2.863 502.766 1.864 512 10.018 512zM339.729 212.351V160h125a15.001 15.001 0 0012.736-22.924L451.285 95l26.18-42.076A14.998 14.998 0 00464.729 30h-125V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v197.351c8.573-9.413 21.229-9.602 30 0zM406.955 349.174l-35.808 33.36c-2.88 2.683-6.552 4.025-10.225 4.025s-7.345-1.342-10.225-4.025l-25.972-24.196-25.967 24.195c-5.761 5.368-14.69 5.368-20.451 0l-35.803-33.359-103.213 147.082c-4.65 6.627.09 15.744 8.186 15.744h354.505c8.096 0 12.836-9.117 8.186-15.744z"></path>
      <path d="M288.533 351.057l25.966-24.195c5.76-5.367 14.689-5.368 20.45-.001l25.973 24.197 28.894-26.307-56.901-81.085c-1.991-2.837-5.088-4.256-8.186-4.256-3.097 0-6.195 1.419-8.186 4.256l-56.901 81.085z"></path>
    </svg>
  );
}

export default MountainSVG;
