import React from "react";

function LunchSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 511.999 511.999"
      version="1.1"
      viewBox="0 0 511.999 511.999"
      xmlSpace="preserve"
    >
      <path
        fill="#995C0D"
        d="M400.775 331.434h-19.972v-9.986c0-25.637 20.863-46.495 46.508-46.495h9.986v19.972h-9.986c-14.632 0-26.536 11.898-26.536 26.523v9.986z"
      ></path>
      <path
        fill="#2BA5F7"
        d="M342.657 508.306c5.233 2.33 10.838 3.528 16.484 3.689h-181.28c19.932 0-50.716-194.927-50.716-194.927l-11.064-153.332h245.23s-2.889 51.275-6.95 108.022a4915.093 4915.093 0 01-3.675 48.239l-.12 1.451c-3.209 39.105-6.751 74.402-10.039 90.407-10.039 48.825-3.928 93.669 1.638 96.225.159.079.332.146.492.226z"
      ></path>
      <path
        fill="#2197D8"
        d="M342.164 508.079c.16.08.333.146.493.226a38.144 38.144 0 00-3.395 1.039 46.981 46.981 0 01-11.171 2.463c-1.212.12-2.437.186-3.662.186h-1.678c-5.818-.16-11.597-1.438-16.976-3.915a55.089 55.089 0 01-28.853-31.343l-17.203-47.613c-18.92-52.38 19.892-107.676 75.587-107.676h15.259c-3.209 39.105-6.751 74.402-10.039 90.407-10.039 48.826-3.927 93.67 1.638 96.226z"
      ></path>
      <path
        fill="#F95428"
        d="M409.883 321.448c55.709 0 94.508 55.296 75.587 107.676l-17.203 47.613a55.52 55.52 0 01-5.459 11.064 54.838 54.838 0 01-10.878 12.343 55.362 55.362 0 01-12.516 7.936c-10.532 4.833-22.568 5.126-33.486 1.265a46.665 46.665 0 00-7.882-2.011 45.739 45.739 0 00-7.257-.586c-5.113 0-10.239.865-15.139 2.596a46.981 46.981 0 01-11.171 2.463c-1.212.12-2.437.186-3.662.186h-1.678c-5.819-.16-11.597-1.438-16.976-3.915a55.089 55.089 0 01-28.853-31.343l-17.203-47.613c-18.92-52.38 19.892-107.676 75.587-107.676h19.093l19.096.002z"
      ></path>
      <path
        fill="#E54728"
        d="M363.591 476.737l-17.203-47.613c-18.228-50.461 17.129-103.621 69.541-107.447a83.273 83.273 0 00-6.048-.229h-38.186c-55.695 0-94.508 55.296-75.587 107.676l17.203 47.613a55.088 55.088 0 0028.853 31.343c5.379 2.477 11.158 3.755 16.976 3.915h1.678c1.225 0 2.45-.067 3.662-.186a46.981 46.981 0 0011.171-2.463 45.451 45.451 0 0114.14-2.575 55.075 55.075 0 01-26.2-30.034z"
      ></path>
      <path
        fill="#2197D8"
        d="M354.36 271.757a4915.093 4915.093 0 01-3.675 48.239c-18.414-4.873-31.982-21.663-31.982-41.608V265.34h13.035c2.117 0 4.194.146 6.218.439a42.793 42.793 0 0116.404 5.978z"
      ></path>
      <path
        fill="#9AD14B"
        d="M353.934 265.779c20.851 3.022 36.855 20.957 36.855 42.634v13.035h-13.035c-23.793 0-43.073-19.28-43.073-43.06V265.34h13.035c2.117 0 4.194.146 6.218.439z"
      ></path>
      <path
        fill="#2197D8"
        d="M194.881 163.735L116.081 163.735 140.959 511.994 200.871 511.994z"
      ></path>
      <path
        fill="#B27214"
        d="M184.066 384.652c16.51 0 29.891 13.381 29.891 29.878 0 8.255-3.342 15.725-8.761 21.144a29.783 29.783 0 01-21.13 8.748l-66.533 37.96-65.961-37.96c-16.51 0-29.891-13.381-29.891-29.891 0-8.255 3.342-15.711 8.748-21.13 5.419-5.406 12.889-8.748 21.144-8.748l67.292-32.754 65.201 32.753z"
      ></path>
      <path
        fill="#995C0D"
        d="M106.428 444.422H51.571c-16.51 0-29.891-13.381-29.891-29.891 0-8.255 3.342-15.711 8.748-21.13 5.419-5.406 12.889-8.748 21.144-8.748h54.856c-8.255 0-15.725 3.342-21.144 8.748-5.406 5.419-8.748 12.875-8.748 21.13 0 16.51 13.382 29.891 29.892 29.891z"
      ></path>
      <g fill="#F7B239">
        <path d="M213.957 465.427v10.472c0 19.935-16.161 36.096-36.096 36.096H57.776c-19.935 0-36.096-16.161-36.096-36.096v-10.472c0-11.6 9.404-21.004 21.004-21.004h150.27c11.599-.001 21.003 9.403 21.003 21.004zM127.145 317.067h33.593c29.392 0 53.219 23.827 53.219 53.219v.013c0 7.928-6.426 14.353-14.353 14.353H36.033c-7.928 0-14.353-6.426-14.353-14.353 0-29.399 23.833-53.232 53.219-53.232h52.246z"></path>
      </g>
      <g fill="#E09B2D">
        <path d="M112.632 511.994H57.776c-19.932 0-36.096-16.151-36.096-36.096v-31.476h54.856v31.476c0 19.945 16.164 36.096 36.096 36.096zM129.755 317.067c-29.385 0-53.219 23.833-53.219 53.232v14.353H36.033c-7.928 0-14.353-6.426-14.353-14.353 0-29.399 23.833-53.232 53.232-53.232h54.843z"></path>
      </g>
      <path
        fill="#F95428"
        d="M321.865 118.307l-19.671-3.45 15.911-90.737c1.604-9.15 7.283-16.794 15.578-20.975 8.295-4.181 17.816-4.194 26.125-.04l63.318 31.658-8.932 17.864-63.319-31.66a9.06 9.06 0 00-8.204.013 9.064 9.064 0 00-4.893 6.588l-15.913 90.739z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M361.311 171.724L361.311 171.711 116.081 171.711 116.081 171.724 94.871 171.724 107.133 106.748 370.271 106.748 382.521 171.724z"
      ></path>
      <path
        fill="#CCC"
        d="M180.977 106.748L174.905 171.711 174.905 171.724 116.081 171.711 101.501 171.711 101.501 171.724 94.871 171.724 107.133 106.748z"
      ></path>
    </svg>
  );
}

export default LunchSVG;
