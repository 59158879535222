import React, { SVGProps } from "react";

function AudioSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93.038"
      height="93.038"
      x="0"
      y="0"
      enableBackground="new 0 0 93.038 93.038"
      version="1.1"
      viewBox="0 0 93.038 93.038"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M46.547 75.521a4.221 4.221 0 01-2.429 3.823 4.222 4.222 0 01-4.493-.571l-23.098-19.14H4.225A4.223 4.223 0 010 55.409V38.576a4.226 4.226 0 014.225-4.226h12.303l23.098-19.14a4.215 4.215 0 014.493-.569 4.224 4.224 0 012.429 3.823l-.001 57.057zm16.237-6.602a4.22 4.22 0 01-3.291-1.226l-.565-.567a4.222 4.222 0 01-.408-5.504 24.381 24.381 0 004.834-14.628c0-5.706-1.896-11.058-5.484-15.478a4.222 4.222 0 01.291-5.65l.564-.565c.844-.844 1.975-1.304 3.199-1.231a4.23 4.23 0 013.061 1.545c4.977 6.09 7.606 13.484 7.606 21.38 0 7.354-2.325 14.354-6.725 20.24a4.228 4.228 0 01-3.082 1.684zm17.468 13.057a4.23 4.23 0 01-6.217.262l-.555-.555a4.226 4.226 0 01-.246-5.707 45.101 45.101 0 0010.568-28.982 44.976 44.976 0 00-11.314-29.847 4.226 4.226 0 01.17-5.783l.554-.555a4.113 4.113 0 013.115-1.242 4.225 4.225 0 013.036 1.417 54.173 54.173 0 0113.675 36.01 54.286 54.286 0 01-12.786 34.982z"></path>
    </svg>
  );
}

export default AudioSVG;
