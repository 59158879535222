import React, { SVGProps } from "react";

function SaladSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512.002 512.002"
      version="1.1"
      viewBox="0 0 512.002 512.002"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#00B04B"
        d="M293.514 122.238c-11.235 3.133-22.672-2.095-27.956-11.718-11.849-21.82-44.424-26.985-62.37-9.415-8.767 8.374-21.492 8.505-30.059 2.598-20.077-13.473-50.515-5.079-59.801 18.916-4.101 10.647-15.059 16.54-26.189 14.462-11.306-2.276-24.958 3.68-31.609 11.242l137.363 137.365a672.27 672.27 0 0026.808 1.677l123.643-125.707c1.278-25.042-25.211-46.402-49.83-39.42z"
      ></path>
      <path
        fill="#FF5368"
        d="M271.407 157.64c26.264 26.264 67.747 28.034 96.061 5.31 3.331-2.673 3.558-7.672.539-10.692L276.789 61.04c-3.019-3.019-8.019-2.792-10.692.539-22.724 28.314-20.954 69.798 5.31 96.061z"
      ></path>
      <path
        fill="#C5324F"
        d="M276.871 152.177c-25.104-25.104-25.104-65.952 0-91.056-6.307-6.307-16.815-5.152-21.639 2.349-19.874 30.901-16.304 72.618 10.712 99.633 27.015 27.015 68.732 30.586 99.633 10.712 7.501-4.824 8.656-15.332 2.349-21.639-25.103 25.104-65.952 25.104-91.055.001z"
      ></path>
      <path
        fill="#FF7C8B"
        d="M307.83 121.218c8.046 8.046 21.092 8.047 29.138 0L307.83 92.079c-8.047 8.047-8.047 21.092 0 29.139z"
      ></path>
      <g fill="#FFCF5D">
        <path d="M273.825 100.858a49.292 49.292 0 00.129 12.582 7.726 7.726 0 0015.304-2.131 33.825 33.825 0 01-.089-8.636 7.726 7.726 0 00-15.344-1.815zM301.957 133.168a33.796 33.796 0 01-6.1-6.105 7.726 7.726 0 00-10.836-1.403c-3.381 2.6-4.008 7.456-1.403 10.836a49.416 49.416 0 008.893 8.903 7.728 7.728 0 009.446-12.231z"></path>
      </g>
      <path
        fill="#4FE267"
        d="M188.829 266.717c-6.505-4.481-8.999-12.822-5.775-20.122 7.243-16.474-3.613-35.695-20.134-38.964-7.128-1.316-13.33-7.679-13.523-16.164-.185-17.579-18.77-31.146-35.351-26.212-7.341 2.148-15.549-.925-19.538-8.037-8.685-15.641-32.248-18.18-43.952-5.155-5.564 6.353-14.168 7.064-22.406 5.251-16.726-3.802-33.58 11.317-26.485 27.469 2.043 4.766 5.855 8.606 10.09 11.015 7.484 4.288 13.69 10.351 12.67 19-1.067 9.254 2.029 18.822 8.635 25.736 5.825 5.897 10.557 7.341 14.243 8.868 1.483.614 2.797 1.244 3.944 2.181 8.58 4.56 19.96 9.482 34.936 14.311 32.176 10.375 71.722 17.355 114.509 20.392-1.187-8.029-5.599-15.383-11.863-19.569z"
      ></path>
      <path
        fill="#FF5368"
        d="M189.866 141.057c-39.827 0-72.113 32.286-72.113 72.113a71.749 71.749 0 0011.066 38.369c33.886 7.018 72.574 11.045 112.954 11.662 12.502-12.968 20.206-30.595 20.206-50.031-.001-39.827-32.288-72.113-72.113-72.113z"
      ></path>
      <g fill="#FF7C8B">
        <path d="M197.592 254.377v-82.414a7.726 7.726 0 10-15.452 0v82.414a7.7 7.7 0 002.368 5.559c3.179.32 6.382.616 9.604.891a7.712 7.712 0 003.48-6.45z"></path>
        <circle cx="189.864" cy="213.166" r="20.604"></circle>
      </g>
      <path
        fill="#C5324F"
        d="M189.866 133.331c-44.024 0-79.839 35.815-79.839 79.839a79.303 79.303 0 008.691 36.153 460.757 460.757 0 0021.161 4.368c-8.995-11.074-14.398-25.177-14.398-40.521 0-35.502 28.884-64.386 64.386-64.386s64.386 28.884 64.386 64.386c0 20.033-9.199 37.955-23.591 49.773 7.043.218 14.147.333 21.296.34 11.089-13.712 17.749-31.145 17.749-50.113-.002-44.024-35.819-79.839-79.841-79.839z"
      ></path>
      <g fill="#FFCF5D">
        <path d="M159.613 174.729a49.264 49.264 0 00-8.805 8.988 7.727 7.727 0 0012.329 9.316 33.815 33.815 0 016.044-6.169 7.728 7.728 0 00-9.568-12.135zM169.151 239.453a33.774 33.774 0 01-6.036-6.175 7.727 7.727 0 00-12.339 9.303 49.277 49.277 0 008.797 8.998 7.694 7.694 0 004.784 1.664 7.726 7.726 0 004.794-13.79zM156.659 217.467a33.786 33.786 0 01.005-8.63 7.727 7.727 0 00-6.67-8.655c-4.229-.552-8.107 2.438-8.655 6.67a49.294 49.294 0 00-.007 12.584 7.729 7.729 0 008.648 6.679 7.727 7.727 0 006.679-8.648zM210.58 186.887a33.774 33.774 0 016.036 6.175 7.712 7.712 0 006.176 3.075 7.727 7.727 0 006.163-12.379 49.277 49.277 0 00-8.797-8.998 7.727 7.727 0 10-9.578 12.127zM227.416 231.8a7.727 7.727 0 00-10.822 1.506 33.815 33.815 0 01-6.044 6.169 7.726 7.726 0 109.568 12.134 49.264 49.264 0 008.805-8.988 7.725 7.725 0 00-1.507-10.821zM238.399 206.902a7.727 7.727 0 00-15.327 1.969 33.786 33.786 0 01-.005 8.63 7.727 7.727 0 0015.325 1.985 49.294 49.294 0 00.007-12.584z"></path>
      </g>
      <path
        fill="#4FE267"
        d="M471.581 121.942c-11.829 2.59-24.184 1.574-32.174-7.501-16.804-18.607-50.639-14.981-63.11 7.364-5.729 10.16-17.513 14.55-28.055 11.481-23.809-7.051-50.493 12.332-50.759 37.445-.277 12.121-9.183 21.211-19.418 23.092-23.723 4.67-39.311 32.128-28.91 55.662 4.629 10.429 1.047 22.344-8.293 28.745-3.678 2.446-6.905 5.652-9.559 9.365 7.988.272 16.054.414 24.179.414 61.364 0 119.519-7.855 163.751-22.118 5.924-1.91 16.614-6.017 21.464-7.929 5.507-8.957 9.787-2.99 23.833-17.138 9.484-9.875 13.931-23.545 12.399-36.765-1.465-12.355 7.446-21.017 18.193-27.142 6.08-3.441 11.555-8.927 14.487-15.735 10.189-23.073-14.011-44.672-38.028-39.24z"
      ></path>
      <path
        fill="#C5324F"
        d="M417.315 249.723c-2.425-28.105-26.062-50.241-54.785-50.241-30.33 0-55.004 24.675-55.004 55.004a54.676 54.676 0 004.053 20.69c31.135-3.724 59.833-9.643 84.274-17.524 8.185-2.64 15.291-5.307 21.462-7.929z"
      ></path>
      <path
        fill="#FF5368"
        d="M386.63 223.581v-.214c-.05-13.103-10.782-23.834-23.885-23.885h-.214c-30.33 0-55.004 24.675-55.004 55.004v.214c.032 8.135 4.183 15.355 10.46 19.672 17.29-2.261 33.767-5.211 49.117-8.805 11.931-10.096 19.526-25.167 19.526-41.986z"
      ></path>
      <g fill="#FF7E40">
        <path d="M185.745 76.317c-6.01-6.009-15.844-6.009-21.853 0-6.009 6.009-6.009 15.844 0 21.853l21.853 21.853c6.01 6.009 15.844 6.009 21.853 0 6.009-6.009 6.009-15.843 0-21.853l-21.853-21.853zM427.837 154.831h-30.905c-8.499 0-15.453 6.954-15.453 15.453s6.954 15.453 15.453 15.453h30.905c8.499 0 15.453-6.954 15.453-15.453s-6.954-15.453-15.453-15.453zM84.787 188.607L62.934 210.46c-6.009 6.009-6.009 15.843 0 21.853 6.009 6.009 15.843 6.009 21.853 0l21.853-21.853c6.01-6.009 6.01-15.843 0-21.853-6.01-6.009-15.844-6.009-21.853 0z"></path>
      </g>
      <path
        fill="#B6B0A2"
        d="M469.568 248.93H252.72L110.541 404.295h.022c0 .646.011 1.29.034 1.932.975 27.356 23.765 48.74 50.787 48.74h182.674c27.022 0 49.812-21.383 50.787-48.74a53.96 53.96 0 00.034-1.932h.022c52.232-26.851 90.246-68.084 103.849-116.073 5.576-19.675-8.975-39.292-29.182-39.292z"
      ></path>
      <path
        fill="#E9E3D5"
        d="M312.824 357.633c33.589-44.498 2.27-108.703-53.025-108.703H35.873c-20.132 0-34.762 19.506-29.23 39.116 13.561 48.069 51.619 89.366 103.924 116.249H219.24c36.39 0 70.893-16.881 93.129-46.061l.455-.601z"
      ></path>
    </svg>
  );
}

export default SaladSVG;
