import React from "react";

function TargetSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M467.55 256L509 200.733a15 15 0 00-12-24h-80.333a15.001 15.001 0 00-12 6L356.467 247a15 15 0 000 18l48.2 64.267c2.833 3.777 7.279 6 12 6H497a15.001 15.001 0 0012-24zM285.625 241h34.716c-7.363-55.538-55.023-98.533-112.541-98.533-62.603 0-113.533 50.931-113.533 113.533s50.93 113.533 113.533 113.533c57.518 0 105.178-42.995 112.541-98.533h-34.716c-7.033 36.557-39.246 64.267-77.825 64.267-43.708 0-79.267-35.559-79.267-79.267s35.559-79.267 79.267-79.267c38.579 0 70.792 27.71 77.825 64.267z"></path>
      <path d="M380.668 347.268l-36.007-48.01c-18.394 58.068-72.787 100.275-136.861 100.275-79.145 0-143.533-64.389-143.533-143.533S128.655 112.467 207.8 112.467c64.074 0 118.467 42.207 136.861 100.275l36.006-48.008a44.73 44.73 0 019.133-9.071C354.403 91.596 286.174 48.2 207.8 48.2 93.035 48.2 0 141.235 0 256s93.035 207.8 207.8 207.8c78.374 0 146.603-43.395 181.999-107.462a44.715 44.715 0 01-9.131-9.07z"></path>
      <path d="M207.8 241h46.924c-6.361-19.852-24.988-34.267-46.924-34.267-27.166 0-49.267 22.101-49.267 49.267s22.101 49.267 49.267 49.267c21.937 0 40.563-14.415 46.924-34.267H207.8c-8.284 0-15-6.716-15-15s6.716-15 15-15z"></path>
    </svg>
  );
}

export default TargetSVG;
