import React from "react";

function BreakfastSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 465.685 465.685"
      version="1.1"
      viewBox="0 0 465.685 465.685"
      xmlSpace="preserve"
    >
      <path
        fill="#F15A24"
        d="M417.685 340.843v-16c17.673 0 32-14.327 32-32v-40h-32v-16h40a8 8 0 018 8v48c-.026 26.498-21.501 47.973-48 48z"
      ></path>
      <path fill="#E6E6E6" d="M9.685 124.843h144v280h-144v-280z"></path>
      <path fill="#29ABE2" d="M153.685 124.843h48v280h-48v-280z"></path>
      <path fill="#0071BC" d="M153.685 124.843l24-56 24 56h-48z"></path>
      <path fill="#CCC" d="M177.685 68.843h-144l-24 56h144l24-56z"></path>
      <g fill="#29ABE2">
        <path d="M33.685 28.843h144v40h-144v-40z"></path>
        <circle cx="81.685" cy="204.843" r="24"></circle>
      </g>
      <g fill="#0071BC">
        <path d="M49.685 252.843h64v16h-64v-16zM49.685 284.843h64v16h-64v-16z"></path>
      </g>
      <path
        fill="#C69C6D"
        d="M60.477 367.395L7.285 405.403a17.328 17.328 0 00-6.4 19.584 17.336 17.336 0 0016.456 11.856h67.544l-24.408-69.448z"
      ></path>
      <path
        fill="#A67C52"
        d="M120.741 337.443l-52.504 15c-8.694 2.487-14.025 11.228-12.256 20.096l12.904 64.304h79.152l-27.296-99.4z"
      ></path>
      <path
        fill="#8C6239"
        d="M265.685 436.843H123.997l-12.496-92.336c-1.286-9.488 5.363-18.221 14.851-19.507a17.339 17.339 0 012.333-.157h137v112z"
      ></path>
      <path
        fill="#B3B3B3"
        d="M273.685 220.843h-16c.026-22.08 17.92-39.974 40-40h116c6.627 0 12-5.373 12-12s-5.373-12-12-12h-96c-15.464 0-28-12.536-28-28s12.536-28 28-28h36c13.255 0 24-10.745 24-24h16c-.026 22.08-17.92 39.974-40 40h-36c-6.627 0-12 5.373-12 12s5.373 12 12 12h96c15.464 0 28 12.536 28 28s-12.536 28-28 28h-116c-13.255 0-24 10.745-24 24z"
      ></path>
      <path
        fill="#F7931E"
        d="M345.549 412.843h-39.864c-44.238-.048-80.088-35.898-80.136-80.136v-87.864a8 8 0 018-8h184.136a8 8 0 018 8v87.864c-.048 44.237-35.898 80.087-80.136 80.136z"
      ></path>
      <g fill="#FBB03B">
        <path d="M321.549 380.843h-8c-30.911-.04-55.96-25.089-56-56v-8h16v8c.026 22.08 17.92 39.974 40 40h8v16zM257.549 284.843h16v16h-16v-16z"></path>
      </g>
      <path fill="#F7931E" d="M409.685 436.843h-160l-16-32h192l-16 32z"></path>
      <path fill="#F15A24" d="M193.685 396.843h272v16h-272v-16z"></path>
    </svg>
  );
}

export default BreakfastSVG;
