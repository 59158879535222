import React from "react";

function DessertsSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#ff7038"
        d="M397 197l20 315c1.793 0 3.577-.321 5.267-.955.406-.1.915-.213 1.514-.352 8.292-1.917 30.318-7.01 50.251-23.396C499.226 466.588 512 436.207 512 397V197z"
      ></path>
      <path
        fill="#ffde46"
        d="M0 197v160a14.999 14.999 0 0010.066 14.165l402 140c1.604.56 3.271.835 4.933.835H417V197z"
      ></path>
      <path
        fill="#ff9d21"
        d="M237 450.197l175.066 60.968c1.604.56 3.271.835 4.933.835H417V197H237z"
      ></path>
      <path fill="#f5f0eb" d="M0 287.66l417 145.224v-31.768L0 255.893z"></path>
      <path
        fill="#ebe1dc"
        d="M237 370.197l180 62.687v-31.768L237 338.43z"
      ></path>
      <path
        fill="#ffef7b"
        d="M424.615 44.077a15 15 0 00-12.57-1.235c-20.274 7.096-98.603 34.355-181.529 63.216-94.377 32.845-191.968 66.809-220.471 76.784a15 15 0 00.035 28.328L272 302.386V377c0 19.33 15.67 35 35 35s35-15.67 35-35v-50.236l70.045 24.394a14.997 14.997 0 0012.57-1.235C428.184 347.82 512 297.274 512 197c0-4.142-.993-102.014-87.385-152.923z"
      ></path>
      <path
        fill="#ffde46"
        d="M424.615 44.077a15 15 0 00-12.57-1.235C392.3 49.752 317.492 75.788 237 103.801v186.396l35 12.189V377c0 19.33 15.67 35 35 35s35-15.67 35-35v-50.236l70.045 24.394a14.997 14.997 0 0012.57-1.235C428.184 347.82 512 297.274 512 197c0-4.142-.993-102.014-87.385-152.923z"
      ></path>
      <g fill="#ff9d21">
        <circle cx="412" cy="132" r="15"></circle>
        <circle cx="432" cy="202" r="15"></circle>
        <circle cx="382" cy="262" r="15"></circle>
        <circle cx="352" cy="192" r="15"></circle>
      </g>
      <path
        fill="#beda4b"
        d="M237 90.003c-8.284 0-15-6.716-15-15 0-18.954-10.532-35.995-27.485-44.473l-4.223-2.111c-7.41-3.704-10.413-12.715-6.708-20.124 3.706-7.409 12.718-10.412 20.124-6.708l4.223 2.111C235.114 17.29 252 44.612 252 75.003c0 8.284-6.716 15-15 15z"
      ></path>
      <path
        fill="#14cd6e"
        d="M237 90.002c8.284 0 15-6.716 15-15 0-17.179-5.399-33.375-15-46.668z"
      ></path>
      <path
        fill="#ff4169"
        d="M237 190c-35.841 0-65-29.159-65-65s29.159-65 65-65 65 29.159 65 65-29.159 65-65 65z"
      ></path>
      <path
        fill="#cf3160"
        d="M302 125c0-35.841-29.159-65-65-65v130c35.841 0 65-29.159 65-65z"
      ></path>
    </svg>
  );
}

export default DessertsSVG;
