import React from "react"

function AppleSVG(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.999 511.999"
			{...props}
		>
			<path d="M461.167 198.492c-20.457-37.356-48.469-60.68-83.256-69.323-40.679-10.108-80.891 2.566-105.638 13.693 1.372-25.119-.592-47.338-5.851-66.558 10.289 13.45 31.822 35.689 64.193 40.19 3.895.541 7.805.812 11.74.812 30.364 0 61.856-16.12 93.784-48.048l10.607-10.606-10.607-10.606C400.075 11.982 364.572-3.909 330.615.811c-46.067 6.404-70.183 48.732-71.187 50.53l-1.347 2.414c-4.766-9.915-10.683-18.764-17.771-26.5C219.596 4.642 196.813.574 194.284.186l-4.563 29.651c.614.095 59.068 10.365 52.422 114.126-24.491-11.361-66.03-25.236-108.055-14.793-34.788 8.643-62.799 31.967-83.256 69.323-20.182 36.853-25.61 82.219-15.697 131.193 8.595 42.466 28.138 84.731 55.029 119.01 31.945 40.723 69.124 63.305 102.725 63.302 5.97-.001 11.836-.715 17.531-2.167 4.428-.767 25.628-4.8 45.581-14.789 19.953 9.988 41.153 14.022 45.581 14.789 5.696 1.452 11.558 2.167 17.531 2.167 33.598 0 70.782-22.583 102.725-63.302 26.891-34.279 46.434-76.545 55.029-119.01 9.91-48.975 4.482-94.34-15.7-131.194z" />
		</svg>
	)
}

export default AppleSVG;
