import * as React from "react"

function PillSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 469.333 469.333"
      {...props}
    >
      <path d="M330.667 0c-37.042 0-71.875 14.427-98.042 40.615l-192 192C14.437 258.812 0 293.635 0 330.667c0 76.458 62.208 138.667 138.667 138.667 37.063 0 71.875-14.427 98.042-40.615l192-191.99c26.208-26.198 40.625-61.021 40.625-98.063C469.333 62.208 407.125 0 330.667 0zM149.875 213.875l-64 63.99C71.771 291.969 64 310.729 64 330.667a10.66 10.66 0 01-10.667 10.667 10.66 10.66 0 01-10.667-10.667c0-25.646 9.979-49.75 28.125-67.885l64-63.99c4.167-4.167 10.917-4.167 15.083 0 4.168 4.166 4.168 10.916.001 15.083zm248.667-7.313l-88.457 88.447-135.753-135.768 88.46-88.46c18.125-18.135 42.229-28.115 67.875-28.115 52.938 0 96 43.063 96 96 0 25.646-9.979 49.761-28.125 67.896z" />
    </svg>
  )
}

export default PillSVG;
