import React from "react";

function EatingSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 351.98 302.66"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={225.46}
          y1={196.03}
          x2={234.01}
          y2={196.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.13} stopOpacity={0.69} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={222.3}
          y1={200.61}
          x2={221.98}
          y2={193.19}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__c"
          x1={233.39}
          y1={196.57}
          x2={243.43}
          y2={196.57}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__d"
          x1={245.63}
          y1={199.6}
          x2={245.14}
          y2={192.07}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__e"
          x1={173.99}
          y1={163.84}
          x2={192.39}
          y2={106.4}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__f"
          x1={89.59}
          y1={192.25}
          x2={185.69}
          y2={215.15}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__g"
          x1={174.52}
          y1={290.85}
          x2={174.52}
          y2={333.22}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__h"
          x1={182.97}
          y1={234.02}
          x2={154.06}
          y2={289.96}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__i"
          x1={170.74}
          y1={252.71}
          x2={151.72}
          y2={289.53}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__j"
          x1={215.27}
          y1={174.56}
          x2={222.32}
          y2={174.56}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__k"
          x1={220.21}
          y1={173.78}
          x2={228.38}
          y2={173.78}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__l"
          x1={203.53}
          y1={229.56}
          x2={240.35}
          y2={202.5}
          xlinkHref="#prefix__a"
        />
      </defs>
      <title>{"11"}</title>
      <path
        d="M349.64 191.76c-6.79 37.95-30.35 69.49-58.72 87.52s-61.1 23.79-93.13 23.36c-18.25-.24-36.91-2.55-53.44-11.93-.83-.47-1.65-1-2.47-1.46-2.45-1.51-4.86-3.15-7.24-4.84-8.56-6.08-16.85-12.84-26.2-16.76-14.07-5.88-29.46-4.86-44-8.93-36.15-10.16-62.7-53.69-64.35-98.69S19.2 71.09 49.67 45.31c28.68-24.28 66.2-34.69 100.83-30 16.77 2.28 29.7-5.63 46-10.09 18.87-5.15 38.46-6.87 57.65-3.46 32.24 5.74 63.41 27.27 79.71 61.48 10 21 14 45.46 16.39 69.6 1.94 19.6 2.84 39.63-.61 58.92z"
        fill="#27c478"
        opacity={0.18}
        style={{
          isolation: "isolate",
        }}
      />
      <path
        d="M170.07 268.19c4.1-2.72 9.71-1.53 14.26-3.42a120.41 120.41 0 016.26 21.19 32.06 32.06 0 0116.08-10 201.85 201.85 0 009.38 25.21 165.05 165.05 0 0135.51-8.67 11.87 11.87 0 004.45-1.16 9.1 9.1 0 003.57-4.67c5-12.38-2.07-26.07-8.95-37.52l-22.26-37.05c-3.49-5.8-7.1-12.67-4.72-19 1.07-2.86 3.26-5.17 4.58-7.93 3.69-7.68-.18-17.16-6.34-23.06-1.59-1.52-3.39-3-4.19-5a12.68 12.68 0 01-.59-4.47l-.47-50.64c-.08-9-.84-19.4-8.13-24.68a15.8 15.8 0 00-17.5-.18 18.17 18.17 0 00-7.83 16c.2 3.74 1.38 7.93-.85 11-1.27 1.73-3.38 2.6-5.39 3.37-9.36 3.58-20 7.94-23.21 17.45-1.9 5.7-.55 11.9.1 17.88a73.46 73.46 0 01-5.78 37.22c-3.9 8.86-9.68 17.42-9.73 27.1 0 6.93 2.91 13.47 4.7 20.16s2.22 14.51-2.13 19.9c-1.4 1.73-3.25 3.14-4.25 5.14-1.51 3-.73 6.64.12 9.91q3.18 12.24 7.08 24.28a14.46 14.46 0 002.52 5.2c5 5.71 16.59 8.57 19.13-.32 2.14-7.66-3.69-17.8 4.58-23.24z"
        fill="#27c478"
        opacity={0.18}
        style={{
          isolation: "isolate",
        }}
      />
      <path
        d="M193.34 160.62a4.78 4.78 0 011.31-.57 1.61 1.61 0 011.37.24c.62.5.61 1.44 1 2.15.19.39.51.72.69 1.12a.44.44 0 010 .4c-.08.15-.29.18-.46.2-1.23.08-2.47.13-3.7.14-1 0-2.95.36-3.84-.09-2.1-1.02 2.9-3.18 3.63-3.59z"
        fill="#27c478"
      />
      <path
        d="M229.6 194.43a4.78 4.78 0 011.31-.57 1.61 1.61 0 011.37.24c.62.5.61 1.44 1 2.15.19.39.51.72.69 1.12a.44.44 0 010 .4c-.08.15-.29.18-.46.2-1.23.08-2.47.13-3.7.14-1 0-2.95.36-3.84-.09-2.1-1.02 2.9-3.18 3.63-3.59z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__a)"
      />
      <path
        d="M182.37 161.11a2.28 2.28 0 01.44-.58 2.09 2.09 0 01.76-.38l1.89-.65a1.09 1.09 0 01.5-.1 1.25 1.25 0 01.58.35 52.49 52.49 0 013.68 3.78 50.3 50.3 0 01-6.38.14c-2.67-.12-2.79-.28-1.47-2.56z"
        fill="#27c478"
      />
      <path
        d="M218.63 194.92a2.28 2.28 0 01.44-.58 2.09 2.09 0 01.76-.38l1.89-.65a1.09 1.09 0 01.5-.1 1.25 1.25 0 01.58.35 52.49 52.49 0 013.68 3.78 50.3 50.3 0 01-6.38.14c-2.67-.12-2.79-.28-1.47-2.56z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__b)"
      />
      <path
        d="M202.21 161.66a1.37 1.37 0 01.48-.1 1.56 1.56 0 01.55.15l3.63 1.59c.16.07.36.21.29.37s-.22.14-.35.14h-5.07c-1.31 0-3.42.4-4.61-.11.49-1.16 3.93-1.67 5.08-2.04z"
        fill="#27c478"
      />
      <path
        d="M238.47 195.47a1.37 1.37 0 01.48-.1 1.56 1.56 0 01.55.15l3.63 1.59c.16.07.36.21.29.37s-.22.14-.35.14H238c-1.31 0-3.42.4-4.61-.11.49-1.16 3.93-1.67 5.08-2.04z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__c)"
      />
      <path
        d="M208.74 160.91a.46.46 0 01.18-.21.41.41 0 01.19 0 1.36 1.36 0 011.1.6 4.24 4.24 0 01.55 1.18l.64 1.93c-.7 0-3.66.29-4.1-.23s1.1-2.76 1.44-3.27z"
        fill="#27c478"
      />
      <path
        d="M245 194.72a.46.46 0 01.18-.21.41.41 0 01.19 0 1.36 1.36 0 011.1.6 4.24 4.24 0 01.55 1.18l.64 1.93c-.7 0-3.66.29-4.1-.23s1.1-2.76 1.44-3.27z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__d)"
      />
      <path
        d="M191.95 141.61a.68.68 0 01.4 0c.23.09.31.37.35.61a5.22 5.22 0 010 2.69 3.48 3.48 0 01-2.12 2 10.11 10.11 0 01-2.94.48 4.39 4.39 0 01-3.35-.74c-1.65-1.58-2.4-.29-4.22-.87-1.65-.52-1.22-1.5 0-1.91 3.67-1.32 8.09-1.4 11.88-2.26z"
        fill="#fff"
      />
      <path
        d="M246.41 269.61c2.85 5.94 6.05 12.26 11.85 15.4 1.75.94 4 2.15 3.74 4.12s-2.87 2.46-4.85 2.38a51.89 51.89 0 01-19.84-4.9 4.88 4.88 0 01-2.1-1.52 5.53 5.53 0 01-.7-2.37c-1.41-9.46-6.51-17.94-9.25-27.1a3.32 3.32 0 01-.08-2.36 3 3 0 011.13-1.11c3.6-2.42 9.1-4.8 11.63-.17 3.1 5.7 5.66 11.78 8.47 17.63zM184.67 299.39a2.92 2.92 0 00.58 2.34 3 3 0 002.14.55h9.35c1-11.77 6.69-22.86 6.94-34.67a1.36 1.36 0 00-.13-.75 1.39 1.39 0 00-1.12-.47c-3.27-.33-6.88-.63-10 .76-3.26 1.47-2.52 6.05-3.16 9.24-1.53 7.66-4.17 15.14-4.6 23z"
        fill="#e4a98a"
      />
      <path
        d="M171.91 84.81a2.25 2.25 0 000 1.45 2.53 2.53 0 00.87.88 8.57 8.57 0 012.93 7.6 16.39 16.39 0 01-3.27 7.74 2.34 2.34 0 00-.53 1.11c0 .66.47 1.2.87 1.73a5.83 5.83 0 01-2.1 8.43c-.71.33-1.55.55-1.95 1.22a3 3 0 00-.18 2c.37 2.9.46 6-.71 8.64-.81 1.84-3.67 5.25-6.11 4.59-1.09-.29-1.24-1-2.49-1.31-1.48-.37-2.65.12-4.16-.81s-2.71-2.19-4.27-2.95c-3.92-1.91-8.51-.07-12.76.92-8.05 1.87-17.32.1-22.86-6a6.81 6.81 0 01-2-4 8 8 0 01.56-3 42 42 0 015.59-11.57 21.14 21.14 0 0110-7.8c6.84-2.33 14.62-.1 21.43-2.53 5-1.77 8.48-5.67 11.84-9.53 2.23-2.55 4-7.9 8.33-7.09 3.09.66 1.43 8.28.97 10.28z"
        fill="#734a39"
      />
      <path
        d="M208.17 118.62a2.25 2.25 0 000 1.45 2.53 2.53 0 00.87.88 8.57 8.57 0 012.93 7.6 16.39 16.39 0 01-3.27 7.74 2.34 2.34 0 00-.53 1.11c0 .66.47 1.2.87 1.73a5.83 5.83 0 01-2.1 8.43c-.71.33-1.55.55-1.95 1.22a3 3 0 00-.18 2c.37 2.9.46 6-.71 8.64-.81 1.84-3.67 5.25-6.11 4.59-1.09-.29-1.24-1-2.49-1.31-1.48-.37-2.65.12-4.16-.81s-2.71-2.19-4.27-2.95c-3.92-1.91-8.51-.07-12.76.92-8.05 1.87-17.32.1-22.86-6a6.81 6.81 0 01-2-4 8 8 0 01.56-3 42 42 0 015.59-11.57 21.14 21.14 0 0110-7.8c6.84-2.33 14.62-.1 21.43-2.53 5-1.77 8.48-5.67 11.84-9.53 2.23-2.55 4-7.9 8.33-7.09 3.09.66 1.43 8.28.97 10.28z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__e)"
      />
      <path
        d="M137.16 121.51a10.67 10.67 0 012.72 4.28c1 2.84.94 5.91.9 8.91l-.18 14.38-.09 6.94c0 1.3-.74 5.43.11 6.38 1.12 1.26 6.34 1.63 7.91 2l12.43 3.11 16.78 4.23a28.42 28.42 0 0012.31-2.93 3.12 3.12 0 01.14 4.18l8.78.65a26.62 26.62 0 017.38 1.26 8.9 8.9 0 015.39 4.88 1.92 1.92 0 01.16 1c-.2 1-1.45 1.23-2.44 1.29l-30.87 1.6a22.71 22.71 0 01-4 0c-1.07-.15-2.11-.44-3.17-.64a37.55 37.55 0 00-4.61-.5q-16.9-1.1-33.77-2.78a52.75 52.75 0 01-8.12-1.26 6.66 6.66 0 01-3-1.46 6.39 6.39 0 01-1.41-2.58 31.4 31.4 0 01-1.2-7.47 115.88 115.88 0 011.63-28.56c.8-4.37 1.19-9.08 3.25-13.08 1.84-3.58 5.74-6.52 9.67-5.67a7.32 7.32 0 013.3 1.84z"
        fill="#e4a98a"
      />
      <path
        d="M165.02 166.44c.13 12 2.53 23.85 3.76 35.78a14.45 14.45 0 01-.14 5.29c-.77 2.84-3.17 5-5.85 6.41-4.08 2.07-8.82 2.5-13.43 2.69a165.92 165.92 0 01-25.8-.91 11.38 11.38 0 01-5.44-1.62c-2.4-1.76-3-5-3.15-7.89-.48-7.38.23-14.78.94-22.13l3.17-32.87 1.59-16.45c.4-4.23.13-8.14 2.06-12.12a15.13 15.13 0 016.2-6.73c3-1.64 6.52-2.09 9.94-2.26a23 23 0 015.44.22 22.18 22.18 0 015.24 1.83 36.73 36.73 0 0110.51 6.94 20.09 20.09 0 016 10.93 29.77 29.77 0 01.24 5.26c-.22 9.24-1.37 18.38-1.28 27.63z"
        fill="#27c478"
      />
      <path
        d="M164.7 219a3.45 3.45 0 01.43-2.11 3.54 3.54 0 011.73-1.07l19.4-7.2a25.15 25.15 0 007.57-3.84c2.18-1.82 3.76-4.62 3.36-7.43a1.9 1.9 0 00-1.09-1.68 2.09 2.09 0 00-1.08 0q-12.32 2.7-24.76 4.78c-1.8.3-3.85.53-5.27-.61a5.89 5.89 0 01-1.75-3.38c-1.71-7.44 0-15.19.21-22.83a2.78 2.78 0 00-.53-2.05c-.72-.72-1.94-.45-2.84 0a12.24 12.24 0 00-5.64 7.48 38 38 0 00-1.17 9.52q-.95 24.24-3.58 48.36c-.28 2.63-.43 5.7 1.52 7.49a8 8 0 004.23 1.61 57 57 0 006 .59 6.73 6.73 0 003.45-.5c3.31-1.74.79-9.34.55-12.21q-.67-7.37-.74-14.92z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__f)"
      />
      <path
        d="M134.74 110.86c.16 3.6 1.21 7.37 3.93 9.74s7.37 2.66 9.69-.09c1.53-1.81 1.72-4.37 1.73-6.74s-.09-4.58-.29-6.86c4.27.52 8.55-1.62 11.44-4.8s4.57-7.28 5.72-11.42a57.78 57.78 0 002.08-15.5 12.59 12.59 0 00-1.07-5.84 10.67 10.67 0 00-4-3.89 19.31 19.31 0 00-11.87-2.93 15.7 15.7 0 00-10.72 5.62c-3.22 4-4 9.43-4.67 14.54-1.19 9.39-2.39 18.69-1.97 28.17z"
        fill="#e4a98a"
      />
      <path
        d="M152.81 80.53c-4.18 1-9.49.22-13.67-.14a6.8 6.8 0 01-4.08 8.26 2.17 2.17 0 00-1.27.73 2.05 2.05 0 00-.16 1.21 13.33 13.33 0 01-5.49 11.46c-.72.51-1.55 1-1.76 1.89a3.3 3.3 0 00.17 1.61 17.66 17.66 0 011 6.65c-.26 2.24-1.69 4.51-3.89 5a3.48 3.48 0 00-1.85.64 2.51 2.51 0 00-.53 1.25c-.55 2.7-1.48 6-4.18 6.52-1.4.27-3.27-.16-3.92 1.1-.45.89.14 1.94.13 2.94 0 4.15-4.78 1.49-6.3.31-3.77-2.9-7.66-6-9.74-10.24s-1.72-10.15 2.09-13c2.7-2 7.07-2.79 7.57-6.12a7.75 7.75 0 00-.69-3.64 35.69 35.69 0 01-1.7-7.7 8.24 8.24 0 01.47-4.57 9.44 9.44 0 012.29-2.68l8.32-7.57a13.79 13.79 0 001.88-1.94 9.14 9.14 0 001-1.78c1.22-2.77 2-6.44 4.89-7.22a12.3 12.3 0 002-.44 5 5 0 001.83-1.82c2.81-4 6.13-7.76 10.44-10s9.81-2.73 14-.21a12.41 12.41 0 002 1.14 7.36 7.36 0 002.29.36 11.17 11.17 0 014.82.78c2.24 1.16 3.33 4 5.64 5 1.24.54 2.73.52 3.78 1.37 1.66 1.35 1.24 4 .66 6 2.59.84 5.67 2.28 5.8 5a4.65 4.65 0 01-2.77 4.08 10.2 10.2 0 01-5 1c-.26-1.41-.45-4.86-2.27-5.31s-2.9 2.13-3.9 3.31c-2.37 2.81-5.61 5.6-9.22 6.65z"
        fill="#734a39"
      />
      <path
        d="M134.04 87.3a2.18 2.18 0 00-.6.53 6 6 0 00-1.17 1.72 6 6 0 00.45 4.57 5.79 5.79 0 002.9 3.2 6 6 0 001.37-2.95 16.17 16.17 0 00.67-5c-.17-1.35-2.4-2.69-3.62-2.07zM117.42 177.59a19 19 0 01-5.85-.8 11.65 11.65 0 01-7-5.86c-4.12-8.19 0-18.91 3.45-26.59a125.44 125.44 0 017-13.27c1-1.73 2.17-3.41 3.23-5.13 1.35-2.18 1.87-4.8 3.63-6.75a5.23 5.23 0 015-1.79 6.2 6.2 0 012.27 1.35 9.05 9.05 0 013.31 5.26 10.9 10.9 0 01-1.26 6.11c-2.43 5.39-6.07 10.13-9.14 15.19s-5.65 10.69-5.56 16.6c0 3.12 5 1.4 7.07.95a99.1 99.1 0 0010.81-3.17 224.26 224.26 0 0027.87-11.91 7.046 7.046 0 116.35 12.58c-8.67 4.39-35.44 17.23-51.18 17.23zm-.28-13z"
        fill="#e4a98a"
      />
      <path
        d="M165.74 144.13a10.48 10.48 0 00-5.33 2.72 8.1 8.1 0 00-1.76 8.3 15.35 15.35 0 001.8 3.21 8.56 8.56 0 002.14 2.45 8.43 8.43 0 003.8 1 7.27 7.27 0 002.59 0 9.81 9.81 0 001.9-.86c2.14-1.14 4.45-1.93 6.61-3a12 12 0 005.35-4.76 15.12 15.12 0 001.33-4.49 4.85 4.85 0 00.11-1.61 3.46 3.46 0 00-1.43-2.14 8.77 8.77 0 00-4.6-1.38c-1.24-.09-2.49-.09-3.73-.08s-2.62.22-4 .22a23 23 0 00-4.78.42z"
        fill="#e4a98a"
      />
      <path
        d="M149.54 94.28a.44.44 0 00-.3.07.39.39 0 00-.07.26 6.43 6.43 0 001 3.69 3.9 3.9 0 003.22 1.82c1.87 0 6-2.11 4.56-4.56-1.13-1.9-6.5-1.18-8.41-1.28zM221.19 163.4l-6.26 17.26c-1 2-1.75 5.36-7.67 6.14-.52.07-1.08.12-1.69.15-4.31.19-8.41.81-14.8.21-4.49-.42-8.09-.54-9.53-5.18l-3.56-14.31-1.06-4.25z"
        fill="#fff"
      />
      <path
        d="M128.81 236.56c8.13 6 18.38 8.51 28.46 9.37s20.23.22 30.34.7c.59 0 1.34 8.89 1.41 9.79a79.27 79.27 0 01.16 10.79c-.19 3.64.29 4 3.88 5a38.76 38.76 0 0010.14 1.51 1.82 1.82 0 001.22-.3 1.73 1.73 0 00.42-1.15l3.07-26.08a119.22 119.22 0 001.17-16.22 4.2 4.2 0 00-.45-2.19 4.38 4.38 0 00-1.66-1.34l-9.23-5.25c-3.32-1.88-6.64-3.77-10.13-5.31-3-1.31-6-2.37-9-3.61-3.65-1.51-7.19-3.28-10.95-4.48-10.58-3.38-20.51 2-31.11 2.21-5.48.1-11.8-1.05-17 1.09-10.31 4.19 4.56 22 9.26 25.47z"
        fill="#3e3e52"
      />
      <path
        d="M208.26 205.7a16.1 16.1 0 015.83.73 12.47 12.47 0 013.92 2.69c4.63 4.4 7.48 10.34 9.89 16.26 2.78 6.82 11.18 26.87 11.18 26.87-2.21 1.37-12 4.93-14.55 5.39a89.33 89.33 0 00-11.34-21.19 11.77 11.77 0 00-2.82-3 12.13 12.13 0 00-5.31-1.55 126 126 0 00-19.22-.85c-5.36.17-11.75.69-16.44-2.44-3.4-2.28-6.79-5.39-10.11-8-2.09-1.61-15.05-11.66-6.81-13.05 5.65-.95 29.79-1.93 35.51-1.92z"
        fill="#3e3e52"
      />
      <path
        d="M141.88 266.85v22.4c-2.45-1.51-4.86-3.15-7.24-4.84v-17.56a3.62 3.62 0 016.18-2.56 3.58 3.58 0 011.06 2.56z"
        fill="#27c478"
      />
      <path
        d="M178.14 300.66v22.4c-2.45-1.51-4.86-3.15-7.24-4.84v-17.56a3.62 3.62 0 016.18-2.56 3.58 3.58 0 011.06 2.56z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__g)"
      />
      <path
        d="M129.99 225.07c-8.32-8.26-14.4-18.79-23.82-25.77a3 3 0 00-2.07-.81 3 3 0 00-1.85 1.51c-10 14.54-7.38 35.66 4.2 48.95 10.29 11.79 26.29 17.43 41.66 16.94 3.49-.11 8.68.17 11-2.92 1.91-2.59 1.6-8 1.26-11.08-.61-5.5-3.43-8.74-8-11.63-7.78-4.93-15.73-8.6-22.38-15.19z"
        fill="#27c478"
      />
      <path
        d="M195.32 296.78c-1.69 2.3-5 2.73-8 2.85-1 0-2 0-2.94.07-15.39.49-31.4-5.15-41.66-16.94a43.08 43.08 0 01-9.54-36.34 35.21 35.21 0 015.33-12.61 2.29 2.29 0 013.92-.7c9.42 7 15.5 17.51 23.83 25.77 6.65 6.59 14.59 10.26 22.36 15.19 4.54 2.89 7.36 6.13 8 11.63.31 3.07.61 8.49-1.3 11.08z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__h)"
      />
      <path
        d="M187.31 299.63c-1 0-2 0-2.94.07-15.39.49-31.4-5.15-41.66-16.94a43.08 43.08 0 01-9.54-36.34c9.37 7 15.44 17.46 23.73 25.69 6.65 6.59 14.6 10.27 22.36 15.2 4.54 2.88 7.36 6.13 8 11.63 0 .22.03.45.05.69z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__i)"
      />
      <path
        d="M143.39 150.08c-.11.57.46 1.09 1 1.16a3.64 3.64 0 001.7-.38c5.16-2 10.77-2.15 16.1-3.52 3.26-.83 1.54-1.76-.82-1.41-2.91.43-5.8 1.09-8.66 1.74-.97.23-9.18 1.64-9.32 2.41z"
        fill="#fff"
      />
      <path
        d="M179.21 141.81a2 2 0 01-.19-.9 2 2 0 01.42-.88l1.62-2.32c.07-.1.16-.21.28-.22a.39.39 0 01.29.11 18 18 0 012.74 2.16 6 6 0 011.69 3 7.69 7.69 0 00-3.43.5c-.53.2-1.73 1-2.32.7s-.9-1.64-1.1-2.15z"
        fill="#27c478"
      />
      <path
        d="M215.47 175.62a2 2 0 01-.19-.9 2 2 0 01.42-.88l1.62-2.32c.07-.1.16-.21.28-.22a.39.39 0 01.29.11 18 18 0 012.74 2.16 6 6 0 011.69 3 7.69 7.69 0 00-3.43.5c-.53.2-1.73 1-2.32.7s-.9-1.64-1.1-2.15z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__j)"
      />
      <path
        d="M186.91 137.41a1.9 1.9 0 01.44-.32 1.69 1.69 0 01.67-.12l1.56-.07a.78.78 0 01.34 0 .75.75 0 01.28.33 28 28 0 011.92 4.47 5.22 5.22 0 01-1.31.23 15.59 15.59 0 00-2.11.55 21 21 0 01-4.4.53.24.24 0 01-.22 0c-.51-.51.59-2.47.83-2.94a9.74 9.74 0 012-2.66z"
        fill="#27c478"
      />
      <path
        d="M223.17 171.22a1.9 1.9 0 01.44-.32 1.69 1.69 0 01.67-.12l1.56-.07a.78.78 0 01.34 0 .75.75 0 01.28.33 28 28 0 011.92 4.47 5.22 5.22 0 01-1.31.23 15.59 15.59 0 00-2.11.55 21 21 0 01-4.4.53.24.24 0 01-.22 0c-.51-.51.59-2.47.83-2.94a9.74 9.74 0 012-2.66z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__k)"
      />
      <path
        d="M246.42 201.46l-2.9 19.15c-.52.07-1.08.12-1.69.15-4.31.19-8.41.81-14.8.21-4.49-.42-8.09-.54-9.53-5.18l-3.56-14.31z"
        transform="translate(-36.26 -33.81)"
        fill="url(#prefix__l)"
      />
    </svg>
  )
}

export default EatingSVG;
