import React from "react";

function EmailSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="illustration"
      data-name="Layer 1"
      viewBox="0 0 385.08 304.02"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="878.88"
          x2="882.97"
          y1="289.58"
          y2="357.84"
          gradientTransform="rotate(-19.29 231.003 2034.296)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="854.56"
          x2="946.44"
          y1="301"
          y2="416.51"
          gradientTransform="rotate(-19.29 158.13 2084.239)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01"></stop>
          <stop offset="0.08" stopOpacity="0.69"></stop>
          <stop offset="0.21" stopOpacity="0.32"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="286.71"
          x2="-1.85"
          y1="217.79"
          y2="20.55"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="218.89"
          x2="194.42"
          y1="132.16"
          y2="258.55"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="202.95"
          x2="290"
          y1="136.07"
          y2="206.24"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="199.53"
          x2="362.44"
          y1="184.28"
          y2="86.14"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="254.44"
          x2="311.18"
          y1="142.72"
          y2="219.19"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="205.72"
          x2="226.17"
          y1="136.03"
          y2="165.5"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-9"
          x1="165.73"
          x2="46.65"
          y1="211.85"
          y2="291.24"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-10"
          x1="372.76"
          x2="379.58"
          y1="157.53"
          y2="271.49"
          gradientTransform="rotate(-7.33 170.83 1958.444)"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-11"
          x1="332.15"
          x2="485.56"
          y1="176.6"
          y2="369.46"
          gradientTransform="rotate(-7.33 -5.498 2108.534)"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-12"
          x1="-536.73"
          x2="-532.65"
          y1="334.24"
          y2="402.49"
          gradientTransform="rotate(28.37 350.092 1917.704)"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-13"
          x1="-561.05"
          x2="-469.18"
          y1="345.66"
          y2="461.16"
          gradientTransform="rotate(28.37 381.853 1866.344)"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
      </defs>
      <path
        fill="#27c478"
        d="M97.48 65.76c27-3.92 54.8-1.22 81.57-6.6 28-5.63 53.5-19.8 80.49-29.21s58.16-13.64 83.2.15c15.33 8.45 26.61 22.76 35.46 37.9a201.2 201.2 0 0127.45 98.28c.25 17.14-1.74 34.4-7 50.72-17.58 54.54-70.79 92.29-126.9 103.93-61.62 12.79-128.62-2.8-178.38-41.31C51 246.77 9.1 188.2 23.47 134.85 33 99.37 57 71.64 97.48 65.76z"
        opacity="0.18"
        transform="translate(-20.59 -21.26)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#27c478"
        d="M309.28 211.82l-30.74 22.75a3.07 3.07 0 01-4.29-.64L261.5 216.7a2.87 2.87 0 01-.5-1.08 3.06 3.06 0 011.14-3.21l30.75-22.75a3.08 3.08 0 012.56-.51 3.14 3.14 0 011.73 1.15l12.75 17.24a3.06 3.06 0 01-.65 4.28z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient)"
        d="M295.45 189.15l-5.69 25.56-28.76.91a3.06 3.06 0 011.14-3.21l30.75-22.75a3.08 3.08 0 012.56-.51z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-2)"
        d="M258.85 193.78L259.79 211.94 288.69 190.56 271.2 184.31 269.17 193.45 258.85 193.78z"
      ></path>
      <path
        fill="#27c478"
        d="M315 66.38l-34.34 48.93-6.6-18.31a637 637 0 00-67.21 21.91c-25.34 10.09-49.74 22.63-56.75 35.59-16.07 29.77 21.68 42.93 21.68 42.93a103.9 103.9 0 01-21.55-.52c-18.83-2.47-42.21-11.59-35.44-42.41 5.35-24.35 40.18-44.83 74.4-59.42h.05c5-2.12 10-4.11 14.86-6l.14-.06a545.89 545.89 0 0155.93-18L256.88 55z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-3)"
        d="M150.1 154.5c-16.07 29.77 21.68 42.93 21.68 42.93a103.9 103.9 0 01-21.55-.52 117.09 117.09 0 01-7.92-10.33c-21.67-31.52 24.81-56.36 24.81-56.36 3.87-14.13 22.07-25.82 22.07-25.82v-9.32h.05l14.86-6 .14-.06 3.27 27.32-.66 2.55c-25.34 10.11-49.74 22.65-56.75 35.61z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#b2857f"
        d="M255.48 257.3a12.47 12.47 0 00-1.66 5.09 8.77 8.77 0 001.67 5 15.1 15.1 0 009.29 6.31 2.24 2.24 0 001.51-.07 1.81 1.81 0 00.73-.86c.91-1.85.54-4.05-.06-6s-1.42-3.93-1.33-6c.15-3.87 5.68-11.34-.76-13.16-5.37-1.56-7.68 6.24-9.39 9.69z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#27c478"
        d="M251.7 262.35s-1.9 1.56-1.54 3.28 7.19 13.71 16.92 12.34-6.25-11-6.25-11z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M254.64 257.92a1.66 1.66 0 011.59 1.48 6.88 6.88 0 004.44 5c2.54.69 4.86-1.43 6-.83s4.34 8.95 3.5 11.33-4.78 2.32-8.28.69-9.88-8.14-10.37-10.71 1.73-6.27 3.12-6.96z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#b2857f"
        d="M214.22 271.13a13.85 13.85 0 00-1.6 5.74 9.86 9.86 0 002.1 5.43 16.81 16.81 0 0010.64 6.58 2.46 2.46 0 001.67-.15 2.21 2.21 0 00.78-1c.92-2.1.39-4.53-.36-6.69s-1.75-4.29-1.79-6.57c0-4.3 5.77-12.89-1.47-14.6-6.05-1.43-8.25 7.35-9.97 11.26z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#27c478"
        d="M210.25 277s-2 1.83-1.55 3.72 8.63 14.9 19.39 12.9-7.46-11.85-7.46-11.85z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M213.3 271.88a1.85 1.85 0 011.85 1.58 7.57 7.57 0 005.17 5.31c2.86.64 5.35-1.81 6.61-1.21s5.26 9.75 4.42 12.42-5.21 2.81-9.18 1.17-11.38-8.58-12-11.41 1.62-7.02 3.13-7.86z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#473f47"
        d="M235.79 166.84a4.93 4.93 0 01.8.21c6.32 2.4 13.93 13.1 18.23 18.18a90.47 90.47 0 0113.82 21.36c7.26 16.21 7.93 36.35-2.27 50.89l-7.84-1.64a3.39 3.39 0 01-1.85-.81 3.5 3.5 0 01-.53-2.71 70.29 70.29 0 00-.77-16.77 33.57 33.57 0 00-3.44-10.88c-2.46-4.43-6.34-7.86-10.12-11.22-12-10.63-22.76-21.62-28.71-36.65 5.37-2.64 16.89-10.98 22.68-9.96z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#473f47"
        d="M217.63 175a3.41 3.41 0 01.73.4c5.59 3.8 10.5 16 13.5 21.92a91.09 91.09 0 018.46 24c3.27 17.45-.76 37.19-14.07 49l-7.25-3.43a3.44 3.44 0 01-1.61-1.21 3.53 3.53 0 01.12-2.77 70.1 70.1 0 003.16-16.49 33.47 33.47 0 00-.81-11.38c-1.35-4.87-4.33-9.11-7.22-13.27-9.15-13.12-17.09-26.33-19.37-42.33 5.83-1.32 18.98-6.74 24.36-4.44z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M250.2 136.91a.57.57 0 010 .13c.09 1.67.64 3.14.21 4.84-.46 1.89-2.22 3.6-4.14 3.32-1.6-.23-2.69-1.67-3.92-2.72a17.34 17.34 0 00-4.24-2.5c-2-.93-4.06-1.8-6.12-2.6-1.32-.52-3.84-.84-4.4-2.19s-.38-3.48-.54-4.91l-2.49-22.69 23.17 19a34.3 34.3 0 012.21 6.47 18.41 18.41 0 01.26 3.85z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M233 139.41c.14 1.34.45 2.67.61 4 .84 7.39-.07 15.22 2.93 22.23.68 1.56 1.56 3.22 1.16 4.88a6.12 6.12 0 01-2.77 3.32c-6.12 4.28-13.55 6.19-20.79 8-5.45 1.38-13.12 3.89-18.56 1.47-2.32-1-3.32-3.32-4.27-5.6-3-7.26-4.58-15.26-6-23-.18-1-.37-2-.54-3.05Q183 141.19 182 130.54c-.46-5.16-.67-10.76 2.27-15 3.9-5.66 12.16-10 18.59-12a22.32 22.32 0 016.43-1.07 27.33 27.33 0 019.06 2c3.43 1.25 6.48 2.29 8.43 5.33a44.3 44.3 0 014.92 11.34 42 42 0 011.53 11.48c0 1.9-.26 3.82-.25 5.71a5.1 5.1 0 00.02 1.08z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#020202"
        d="M207.4 184.61c-2.08.87-7.24-.56-8.91-.67-1-.06-2-.46-3-.58-5-.69-7.13-14.39-8-18-2.34-9.65-5.4-19.57-6.62-29.37-.46-3.79-.79-7.66.06-11.37s3-7.29 6.45-9c6-2.94 10.05 16.65 11.36 20.65 1.64 5 3.29 10 4.92 15 .72 2.24.95 4.73 2.18 6.78 1.87 3.11 3.45 2.52 6.58 2 6.57-1.14 12.71-3.93 19.18-5.49a2.09 2.09 0 011.65.08 1.93 1.93 0 01.67 1.13 14.63 14.63 0 01.62 3.89 2.33 2.33 0 01-.16 1.11 2.51 2.51 0 01-.91.86 49.77 49.77 0 01-14.23 6.47c-4 1.12-7.79 3-10.49 6.34-2.43 3 .73 6.16-.59 9.41a1.37 1.37 0 01-.76.76z"
        opacity="0.1"
        transform="translate(-20.59 -21.26)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#27c478"
        d="M182.25 148.24L220.59 135.31 248.71 151.35 209.58 167.39 182.25 148.24z"
      ></path>
      <path
        fill="url(#linear-gradient-4)"
        d="M182.25 148.24L220.59 135.31 248.71 151.35 209.58 167.39 182.25 148.24z"
      ></path>
      <path
        fill="#27c478"
        d="M183.83 146.17L222.17 133.24 250.29 149.28 211.16 165.32 183.83 146.17z"
      ></path>
      <path
        fill="url(#linear-gradient-5)"
        d="M213.04 163.11L244.76 149.1 229.28 140.6 197.74 152.54 213.04 163.11z"
      ></path>
      <path
        fill="#27c478"
        d="M211.16 165.32L248.97 165.32 281.52 145.05 250.29 149.28 211.16 165.32z"
      ></path>
      <path
        fill="#b2857f"
        d="M218.85 93.67c-.39 1.41-.81 2.82-1.26 4.22-1 3.2-3 6.12-6.48 6.89a16.65 16.65 0 01-2.33.28h-.52a.08.08 0 01-.07 0h-.09a3.9 3.9 0 00-1.16.12.66.66 0 00-.3.09c-1 .46-.92 2.08-.85 3a4.6 4.6 0 01-.34 3.08s0 .06-.06.1a3.09 3.09 0 01-2.17 1 6.81 6.81 0 01-4.79-1.46 7.9 7.9 0 01-1.83-2.55 5.79 5.79 0 01-.34-.85 5.45 5.45 0 01-.13-1.51 16.41 16.41 0 01.22-2.32 45.24 45.24 0 00.87-4.59 22.8 22.8 0 01.16-2.34 11.14 11.14 0 00.28-1.51c0-1.47-.75-2.89-.76-4.38a21.62 21.62 0 01.41-4.39 17.33 17.33 0 013.63-7.81c3.43-4 9.51-6 14.29-3.72a10.22 10.22 0 015.17 5.64c1.6 4.21-.4 8.9-1.55 13.01zM213.87 165.86c.84-.1 1.69-.19 2.53-.26a3.79 3.79 0 011.5.09 1.4 1.4 0 011 1 1.17 1.17 0 00.11.53.8.8 0 00.5.27c3 .78 6.1 2 7.89 4.47a1.83 1.83 0 01.4 1.76c-.41.84-1.61.72-2.51.47a1.1 1.1 0 010 1.26 1.39 1.39 0 01-1.17.56 2.48 2.48 0 00-.9 0 2.27 2.27 0 00-.56.37 3.72 3.72 0 01-2.89.52c-1-.15-2-.49-3-.57-1.55-.12-2.94.39-4.38-.24a21.27 21.27 0 00-5-1.74 2.61 2.61 0 01-1.45-.75 2.45 2.45 0 010-2.18 11 11 0 011.38-3.47c.85-1.2 2.27-1.41 3.66-1.65 1.02-.17 1.93-.3 2.89-.44z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M184.25 115.53c-7.35 12.17-14.61 26.27-16.7 40.45-.55 3.73-.85 8.13.9 11.63 2.86 5.7 12.59 6.81 18.19 7.38a115.26 115.26 0 0020.18.27.91.91 0 001.06-1.08l.28-6.47a1.59 1.59 0 00-.12-.89 1.71 1.71 0 00-1.43-.59 39.73 39.73 0 01-11.42-2.65c-3.47-1.38-10.25-5-9.76-9.56.27-2.56 3.08-5.68 4.47-7.7 3.34-4.85 6.94-9.55 10.62-14.15 0 0 .36-26.17-16.27-16.64z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#473f47"
        d="M217.7 87.79a3.62 3.62 0 002.36-1.79c.41 1.15 2.35.8 2.84-.33s0-2.41-.44-3.54a2.84 2.84 0 001.62-4.47c-.33-.37-.79-.69-.88-1.19a2.76 2.76 0 01.17-1.12 3.35 3.35 0 00-1.37-3.07 9.9 9.9 0 00-3.24-1.46 2.44 2.44 0 00-.55-3.18 5.77 5.77 0 00-3.29-1.18c-1.73-.19-3.64-.11-5 1a4.91 4.91 0 00-6 .64 2.35 2.35 0 01-1.12.84 2 2 0 01-.94-.16 6.76 6.76 0 00-4-.41 2.76 2.76 0 00-2.09 3.09 2.45 2.45 0 00-2.09 1.86 1.9 1.9 0 01-.28.86c-.29.34-.8.32-1.23.36a3.27 3.27 0 00.06 6.52 3 3 0 00.43 2.36c.53.85 1.24.91 1.94 1.44.41.32.25.35.23 1a10.69 10.69 0 00.09 1.79 13.15 13.15 0 00.85 3.33c.3.77.68 1.51.95 2.29a18.23 18.23 0 01.74 3.67l1.69-4.78a.36.36 0 000-.2.41.41 0 00-.18-.21 4.1 4.1 0 01-1.18-5 1.67 1.67 0 011.3-1c.68 0 1.2.59 1.53 1.18a8.09 8.09 0 01.79 2 11 11 0 011.87-3c.33-.37.73-1 1.26-.81s.78 1.15 1 1.5a6.27 6.27 0 002.11-1.36 3.65 3.65 0 001.78 3.51c1.26.59 3.09-.25 3.09-1.65 0 .63 2.73-.43 2.93-.55a2.29 2.29 0 002.25 1.22z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#96625e"
        d="M204.51 105.11a13.63 13.63 0 002.43.1.66.66 0 00-.3.09c-1 .46-.92 2.08-.85 3a4.6 4.6 0 01-.34 3.08 3.15 3.15 0 01-.92-.13 2.5 2.5 0 01-.83-.49 5.9 5.9 0 01-1.88-6.32 11.28 11.28 0 002.69.67z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#fff"
        d="M244.14 123.66s18.31 13.51 15.78 27c-2.35 12.55-4.46 22.53-4.46 22.53l-8.57-5s5.73-18.91-6.23-27.13 3.48-17.4 3.48-17.4z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#b2857f"
        d="M245.37 168l-.82 4.25a3.69 3.69 0 00-.06 1.65 1.25 1.25 0 001.17 1c.6 0 1.11-.66 1.71-.54a2.08 2.08 0 01.6.29c1.28.7 2.83 0 4.18-.63a1.11 1.11 0 01.71-.13c.29.08.47.37.72.55a1.44 1.44 0 001.63-.1 3.33 3.33 0 001-1.42 7.78 7.78 0 00-.53-7.42 6.81 6.81 0 00-6.5-2.42c-2.6.41-3.34 2.45-3.81 4.92z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#27c478"
        d="M209.58 167.39L247.39 167.39 279.94 147.12 248.71 151.35 209.58 167.39z"
      ></path>
      <path
        fill="url(#linear-gradient-6)"
        d="M211.16 165.32L209.58 167.39 247.39 167.39 248.97 165.32 211.16 165.32z"
      ></path>
      <path
        fill="#27c478"
        d="M281.52 145.05L279.94 147.12 247.39 167.39 248.97 165.32 281.52 145.05z"
      ></path>
      <path
        fill="url(#linear-gradient-7)"
        d="M281.52 145.05L279.94 147.12 247.39 167.39 248.97 165.32 281.52 145.05z"
      ></path>
      <path
        fill="#fff"
        d="M250.67 150.18L276.53 146.57 249.56 163.11 220.13 163.62 250.67 150.18z"
      ></path>
      <path
        fill="url(#linear-gradient-8)"
        d="M206.61 147.94L221.25 142.51 214.19 137.35 199.17 142.51 206.61 147.94z"
      ></path>
      <path
        fill="#27c478"
        d="M195.87 225.17l-58.09 26.52a5.13 5.13 0 01-6.79-2.53l-14.86-32.56a4.74 4.74 0 01-.45-1.93 5.11 5.11 0 013-4.85l58.09-26.52a5.17 5.17 0 014.37.06 5.1 5.1 0 012.42 2.47l14.84 32.57a5.11 5.11 0 01-2.53 6.77z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-9)"
        d="M195.87 225.17l-58.09 26.52a5.13 5.13 0 01-6.79-2.53l-14.86-32.56a4.74 4.74 0 01-.45-1.93 5.11 5.11 0 013-4.85l58.09-26.52a5.17 5.17 0 014.37.06 5.1 5.1 0 012.42 2.47l14.84 32.57a5.11 5.11 0 01-2.53 6.77z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="#27c478"
        d="M198.56 224l-58.09 26.52a5.13 5.13 0 01-6.79-2.53l-14.86-32.55a5 5 0 01-.46-1.93 5.14 5.14 0 013-4.86l58.09-26.51a5.14 5.14 0 016.79 2.52l14.86 32.57a5.11 5.11 0 01-2.54 6.77z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-10)"
        d="M183.8 182.14l-18.14 39.78-47.3-8.46a5.14 5.14 0 013-4.86l58.09-26.51a5.11 5.11 0 014.35.05z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-11)"
        d="M128.11 197.62L123.36 227.61 177.97 202.69 151.56 186.43 145.07 200.66 128.11 197.62z"
      ></path>
      <path
        fill="#27c478"
        d="M322.93 160.66l-37.52-7.41a3.06 3.06 0 01-2.41-3.6l4.15-21a2.91 2.91 0 01.45-1.1 3.06 3.06 0 013.15-1.32l37.52 7.41a3 3 0 012.1 1.55 3.11 3.11 0 01.32 2.05l-4.16 21a3.06 3.06 0 01-3.6 2.42z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-12)"
        d="M330.37 135.17l-22.72 13-20-20.64a3.06 3.06 0 013.15-1.32l37.52 7.41a3 3 0 012.05 1.55z"
        transform="translate(-20.59 -21.26)"
      ></path>
      <path
        fill="url(#linear-gradient-13)"
        d="M279.87 119.5L267.08 132.43 302.34 139.4 295.18 122.26 287.06 126.91 279.87 119.5z"
      ></path>
    </svg>
  );
}

export default EmailSVG;